<template>
    <a type="button" class="button-search" @click="toggle">
        {{ !isLoggedIn ? $t("login.form.title") : user.firstName?.toLocaleUpperCase() ?? user.name?.toLocaleUpperCase() }}
    </a>
    <BaseLightbox v-model="isOpen" :title="$t('login.form.title')" :subtitle="$t('login.form.subtitle')" :maxWidth="1000">
        <LoginForm />
    </BaseLightbox>
</template>

<script lang="ts" setup>
import BaseLightbox from "@/components/base/BaseLightbox.vue";
import useLoginModal from "@/composables/login/useLoginModal";
import { UserType } from "@/models/Enums";
import { eventBusKey } from "@/models/Symbols/Eventbus";
import { User } from "@/models/store/User";
import { useUserStore } from "@/store/user";
import { computed, inject } from "vue";
import LoginForm from "./LoginForm.vue";

const { isOpen } = useLoginModal();

const userStore = useUserStore();

const isLoggedIn = computed(() => [UserType.Customer, UserType.Agent].includes(userStore.getUserType));

const user = computed(() => userStore.getUser);

const redirect = (url: string) => {
    if (process.env.NODE_ENV === "production") {
        window.location.href = url;
    } else {
        window.location.href = "/my-pages";
    }
};

const toggle = () => {
    if (!isLoggedIn.value) {
        isOpen.value = !isOpen.value;
    } else {
        redirect(`${document.getElementById("login")?.dataset.link}`);
    }
};

const eventBus = inject(eventBusKey);
// if logging in while in the booking flow we need to manually sync the user-store between the modules
eventBus?.on("user-login", (event) => {
    const user = event as User;
    userStore.login(user);
});
</script>

<style lang="scss" scoped>
.button-search {
    color: $c-white;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        cursor: pointer;
    }
}
</style>
